var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Edit Template"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Template Name","label-for":"v-templateName"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-templateName","placeholder":"Template Name","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.label),callback:function ($$v) {_vm.$set(_vm.template, "label", $$v)},expression:"template.label"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Headers At","label-for":"v-headerAt"}},[_c('validation-provider',{attrs:{"name":"header at","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-headerAt","placeholder":"Headers At","type":"number","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.headerAt),callback:function ($$v) {_vm.$set(_vm.template, "headerAt", $$v)},expression:"template.headerAt"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"default Bq DataSet","label-for":"v-defaultBqDataSet"}},[_c('validation-provider',{attrs:{"name":"defaultBqDataSet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-defaultBqDataSet","placeholder":"default Bq DataSet","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.defaultBqDataSet),callback:function ($$v) {_vm.$set(_vm.template, "defaultBqDataSet", $$v)},expression:"template.defaultBqDataSet"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"default Bq Table","label-for":"v-defaultBqTable"}},[_c('validation-provider',{attrs:{"name":"defaultBqTable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-defaultBqTable","placeholder":"default BQ Table","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.defaultBqTable),callback:function ($$v) {_vm.$set(_vm.template, "defaultBqTable", $$v)},expression:"template.defaultBqTable"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Add Created At ","label-for":"v-addCreatedAt"}},[_c('b-form-checkbox',{attrs:{"value":"true"},model:{value:(_vm.template.addCreatedAt),callback:function ($$v) {_vm.$set(_vm.template, "addCreatedAt", $$v)},expression:"template.addCreatedAt"}},[_vm._v(" True ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('h4',[_vm._v("Custom static fields ")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(!(_vm.template.customStaticFields && _vm.template.customStaticFields[0]))?_c('a',{staticClass:"btn btn-success btn-sm",on:{"click":_vm.addCustomField}},[_vm._v("Add field")]):_vm._e()])],1),(_vm.template.customStaticFields && _vm.template.customStaticFields[0])?_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"bqColName","label-for":"v-bqColName"}},[_c('validation-provider',{attrs:{"name":"bqColName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-bqColName","placeholder":"bqColName","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.customStaticFields[0].bqColName),callback:function ($$v) {_vm.$set(_vm.template.customStaticFields[0], "bqColName", $$v)},expression:"template.customStaticFields[0].bqColName"}})]}}],null,false,3323119989)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"col","label-for":"v-col"}},[_c('validation-provider',{attrs:{"name":"col","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-col","placeholder":"col","type":"number","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.customStaticFields[0].col),callback:function ($$v) {_vm.$set(_vm.template.customStaticFields[0], "col", $$v)},expression:"template.customStaticFields[0].col"}})]}}],null,false,2160166284)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"line","label-for":"v-line"}},[_c('validation-provider',{attrs:{"name":"line","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-line","placeholder":"line","type":"number","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.customStaticFields[0].line),callback:function ($$v) {_vm.$set(_vm.template.customStaticFields[0], "line", $$v)},expression:"template.customStaticFields[0].line"}})]}}],null,false,4118426370)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"type","label-for":"v-type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"required":"","state":errors.length > 0 ? false:null,"options":_vm.staticFieldsOptions},model:{value:(_vm.template.customStaticFields[0].type),callback:function ($$v) {_vm.$set(_vm.template.customStaticFields[0], "type", $$v)},expression:"template.customStaticFields[0].type"}})]}}],null,false,3338948525)})],1)],1),(_vm.template.customStaticFields[0].type=='regex')?_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"regex","label-for":"v-regex"}},[_c('validation-provider',{attrs:{"name":"regex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-regex","placeholder":"regex","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.template.customStaticFields[0].regex),callback:function ($$v) {_vm.$set(_vm.template.customStaticFields[0], "regex", $$v)},expression:"template.customStaticFields[0].regex"}})]}}],null,false,3803236044)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"1"}},[_c('a',{staticClass:"btn btn-danger btn-sm mt-2",on:{"click":_vm.removeCustomField}},[_vm._v("remove")])])],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.loading},on:{"click":_vm.saveTemplate}},[_vm._v(" Save ")]),_c('router-link',{attrs:{"to":{name:'documents-templates'}}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }