<template>
  <b-card
    title="Edit Template"
  >

    <validation-observer ref="simpleRules">

      <b-form @submit.prevent>
        <b-row>

          <b-col cols="8">
            <b-form-group
              label="Template Name"
              label-for="v-templateName"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="v-templateName"
                  v-model="template.label"
                  placeholder="Template Name"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="Headers At"
              label-for="v-headerAt"
            >
              <validation-provider
                #default="{ errors }"
                name="header at"
                rules="required|integer"
              >
                <b-form-input
                  id="v-headerAt"
                  v-model="template.headerAt"
                  placeholder="Headers At"
                  type="number"
                  required
                  :state="errors.length > 0 ? false:null"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="default Bq DataSet"
              label-for="v-defaultBqDataSet"
            >
              <validation-provider
                #default="{ errors }"
                name="defaultBqDataSet"
                rules="required"
              >
                <b-form-input
                  id="v-defaultBqDataSet"
                  v-model="template.defaultBqDataSet"
                  placeholder="default Bq DataSet"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="default Bq Table"
              label-for="v-defaultBqTable"
            >
              <validation-provider
                #default="{ errors }"
                name="defaultBqTable"
                rules="required"
              >
                <b-form-input
                  id="v-defaultBqTable"
                  v-model="template.defaultBqTable"
                  placeholder="default BQ Table"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Add Created At "
              label-for="v-addCreatedAt"
            >
              <b-form-checkbox
                v-model="template.addCreatedAt"
                value="true"
              > True
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col cols="8">
            <h4>Custom static fields </h4>
          </b-col>

          <b-col
            cols="4"
            class="text-right"
          >
            <a
              v-if="!(template.customStaticFields && template.customStaticFields[0])"
              class="btn btn-success btn-sm"
              @click="addCustomField"
            >Add field</a>
          </b-col>

        </b-row>

        <b-row v-if="template.customStaticFields && template.customStaticFields[0]">

          <b-col cols="2">
            <b-form-group
              label="bqColName"
              label-for="v-bqColName"
            >
              <validation-provider
                #default="{ errors }"
                name="bqColName"
                rules="required"
              >
                <b-form-input
                  id="v-bqColName"
                  v-model="template.customStaticFields[0].bqColName"
                  placeholder="bqColName"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group
              label="col"
              label-for="v-col"
            >
              <validation-provider
                #default="{ errors }"
                name="col"
                rules="required|integer"
              >
                <b-form-input
                  id="v-col"
                  v-model="template.customStaticFields[0].col"
                  placeholder="col"
                  type="number"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group
              label="line"
              label-for="v-line"
            >
              <validation-provider
                #default="{ errors }"
                name="line"
                rules="required|integer"
              >
                <b-form-input
                  id="v-line"
                  v-model="template.customStaticFields[0].line"
                  placeholder="line"
                  type="number"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col cols="2">

            <b-form-group
              label="type"
              label-for="v-type"
            >
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >

                <b-form-select
                  v-model="template.customStaticFields[0].type"
                  required
                  :state="errors.length > 0 ? false:null"
                  :options="staticFieldsOptions"
                />

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col
            v-if="template.customStaticFields[0].type=='regex'"
            cols="2"
          >
            <b-form-group
              label="regex"
              label-for="v-regex"
            >
              <validation-provider
                #default="{ errors }"
                name="regex"
                rules="required"
              >
                <b-form-input
                  id="v-regex"
                  v-model="template.customStaticFields[0].regex"
                  placeholder="regex"
                  required
                  :state="errors.length > 0 ? false:null"
                />

              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="1">
            <a
              class="btn btn-danger btn-sm mt-2"
              @click="removeCustomField"
            >remove</a>
          </b-col>

        </b-row>

        <b-row>
          <!-- submit and reset -->
          <b-col
            cols="12"
            class="mt-3"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="saveTemplate"
            >
              Save
            </b-button>

            <router-link :to="{name:'documents-templates'}">

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Back
              </b-button>

            </router-link>

          </b-col>

        </b-row>

      </b-form>

    </validation-observer>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  required,
  alpha_num,
  integer,
} from 'vee-validate/dist/rules'
import { throws } from 'assert'

extend('required', required)
extend('alpha_num', alpha_num)
extend('integer', integer)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      template: {},
      loading: false,
      staticFieldsOptions: [
        { value: 'all', text: 'All' },
        { value: 'regex', text: 'Regex' },
      ],
    }
  },
  mounted() {
    this.loadtemplate(this.$route.params.templateId)
  },
  methods: {

    loadtemplate(templateId) {
      this.$store.dispatch('documentTemplate/get', templateId)
        .then(result => {
          this.template = result.data
        },
        error => {

        })
    },
    addCustomField() {
      this.template.customStaticFields.push({})
    },
    removeCustomField() {
      this.template.customStaticFields = []
    },
    saveTemplate() {
      this.$refs.simpleRules.validate().then(valid => {
        if (valid) {
          this.loading = true

          this.$store.dispatch('documentTemplate/update', this.template)
            .then(() => {
              this.$router.push({ name: 'documents-templates' })
            },
            error => {
              this.showToast('Error', error.message || 'Error while saving', 'danger')
            }).finally(() => {
              this.loading = false
            })
        } else {
          this.showToast('Form', 'Check your inputs please', 'warning')
        }
      }, error => {
        console.log(error)
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
